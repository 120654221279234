<template>
  <div class="main-content" id="content">
    <h3 class="marginBottom20">
      {{ `${type === "add" ? "新增" : type === "edit" ? "编辑" : "查看"}权益` }}
    </h3>
    <!-- <el-card style="padding-left: 50px"> -->
    <el-form
      ref="form"
      :model="form"
      label-position="left"
      label-width="80px"
      :rules="rules"
    >
      <el-form-item label="权益名称" prop="name">
        <el-input
          v-model="form.name"
          placeholder="请输入"
          style="width: 400px"
          maxlength="20"
        ></el-input>
      </el-form-item>

      <el-form-item label="权益分类" :span="8" prop="classifyIds">
        <!-- <el-select v-model="form.classifyIds" clearable style="width: 400px">
          <el-option
            v-for="item in classifyList"
            :key="item.id"
            :value="item.id"
            :label="item.name"
          ></el-option>
        </el-select> -->
        <el-checkbox-group v-model="form.classifyIds">
          <el-checkbox
            v-for="item in classifyList"
            :key="item.id"
            :label="item.id"
            >{{ item.name }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <!-- <el-form-item label="权益内容" :span="8" prop="content">
        <FormEditor
          :details="form.content"
          @editoChange="editoChange"
          :disabled="false"
        />
      </el-form-item> -->

      <el-form-item>
        <el-button @click="revert">返回</el-button>
        <el-button type="primary" @click="submit">确认</el-button>
      </el-form-item>
    </el-form>
    <!-- </el-card> -->
  </div>
</template>

<script>
import {
  equityListClassify,
  equityManageAdd,
  equityManageUpdate,
  equityManageView,
} from "@/api/equity/manage";
import FormEditor from "@/components/formComponents/formEditor";
import dayjs from "dayjs";

export default {
  name: "create",
  components: {
    FormEditor,
  },
  data() {
    return {
      classifyList: [],
      id: this.$route.query.id,
      disabled: this.$route.query.type === "view",
      type: this.$route.query.type,
      form: {
        classifyIds: null,
        content: "",
        name: "",
      },
      //校验规则
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        classifyIds: [{ required: true, message: "请选择", trigger: "blur" }],
        // content: [
        //   {
        //     required: true,
        //     message: "请输入权益内容",
        //     trigger: "blur",
        //   },
        //   {
        //     validator(_, value) {
        //       if (value) {
        //         if (value === "<p><br></p>") {
        //           return Promise.reject(new Error("请输入内容"));
        //         }
        //         if (value.length > 65535) {
        //           return Promise.reject(new Error("内容超出最大长度限制"));
        //         }
        //       }
        //       return Promise.resolve(true);
        //     },
        //   },
        // ],
      },
      loadingSubmit: false,
    };
  },
  mounted() {
    this.getClassifyList();
    if (this.type == "edit") {
      this.getView();
    }
  },
  methods: {
    async getView() {
      if (this.id) {
        const { data } = await equityManageView(this.id);
        this.form = {
          ...data,
          classifyIds: data.classifyIds.split(","),
        };
      }
    },

    async submit() {
      if (this.loadingSubmit) {
        return;
      }
      let newVal = {
        ...this.form,
      };
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loadingSubmit = true;
          if (this.type == "add") {
            equityManageAdd({
              ...newVal,
              classifyIds: newVal.classifyIds.join(","),
            })
              .then((res) => {
                // 获取数据成功后的其他操作
                if (res.code == 200) {
                  this.loadingSubmit = false;
                  this.$router.go(-1);
                } else {
                  this.loadingSubmit = false;
                  this.$message.error(res.msg);
                }
              })
              .catch((err) => {
                // loading.close();
                // this.$message.error(err.msg);
                this.loadingSubmit = false;
              });
          } else {
            equityManageUpdate({
              ...newVal,
              id: this.id,
              classifyIds: newVal.classifyIds.join(","),
            })
              .then((res) => {
                // 获取数据成功后的其他操作
                if (res.code == 200) {
                  this.loadingSubmit = false;
                  this.$router.go(-1);
                } else {
                  this.loadingSubmit = false;
                  this.$message.error(res.msg);
                }
              })
              .catch((err) => {
                // loading.close();
                // this.$message.error(err.msg);
                this.loadingSubmit = false;
              });
          }
        }
      });
    },
    //富文本框变化替换值
    editoChange(e) {
      this.form.content = e;
    },
    //权益分类
    getClassifyList() {
      equityListClassify({}).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.classifyList = res.data;
        }
      });
    },
    revert() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.main-content {
  padding-left: 30px;
  padding-right: 40%;
}
</style>
